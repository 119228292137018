<script setup lang="ts">
import { computed } from 'vue'
import { Payee } from '/~/types/api'
import { pluralize } from '/~/utils/format'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { usePayees } from '/~/composables/payees'

const props = defineProps<{
  payee: Payee
}>()

const emit = defineEmits<(event: 'click-remove', payee: Payee) => void>()

const { payeeApprovalDaysSLA } = usePayees()

const statusClass = computed(() => {
  switch (props.payee.status) {
    case 'pending':
      return 'flex items-center gap-2 bg-orange-50 border border-orange-700 rounded-sm px-2 text-sm text-orange-700'
    case 'rejected':
      return 'flex items-center gap-2 bg-red-50 border border-red-700 rounded-sm px-2 text-sm text-red-700'
    default:
      return 'hidden'
  }
})

const statusIcon = computed(() => {
  switch (props.payee.status) {
    case 'pending':
      return 'heroicons/mini/clock'
    case 'rejected':
      return 'heroicons/mini/exclamation-triangle'
    default:
      return ''
  }
})

const statusMessage = computed(() => {
  switch (props.payee.status) {
    case 'pending':
      return `Payee approval is pending security and account verification, which is typically approved within ${
        payeeApprovalDaysSLA.value
      } business ${pluralize(payeeApprovalDaysSLA.value, 'day')}.`
    case 'rejected':
      return `Payments to ${props.payee.name} are not supported.`
    default:
      return ''
  }
})

const actionButtonTextClass = computed(() => {
  return 'text-sm font-normal text-red-700 underline underline-offset-2 hover:no-underline'
})
</script>

<template>
  <div :class="statusClass">
    <base-icon class="flex-shrink-0" :svg="statusIcon" :size="16" />
    <span>{{ statusMessage }}</span>
    <template v-if="payee.status === 'rejected'">
      <span>|</span>
      <base-button look="link" size="sm" @click="emit('click-remove', payee)">
        <span :class="actionButtonTextClass">Remove Payee</span>
      </base-button>
    </template>
  </div>
</template>
